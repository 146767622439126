import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { SyspromptTemplateCodeEditor } from './SyspromptTemplateCodeEditor';
import { ProfileSelectionDTO } from '../../types/ProfileSelectionDTO';
import {
  getTemplates,
  getGlobalTemplate,
  getProfileModifiedTemplate,
  getProfileActivatedTemplate,
  saveGlobalTemplate,
  saveProfileModifiedTemplate,
  activateProfileTemplate,
  deleteGlobalTemplate
} from '../../services/SyspromptManagementService';
import { TagContentReplacer, replaceTagContent } from '../utils/syspromptEditorUtils';
import Loading from '../utils/Loading';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { getProfileParameters } from '../../services/ProfileService';
import { fetchUploadedFiles } from '../../services/FileService';



type EditMode = 'template' | 'profileModified' | 'profileActivated';

const globalTemplateColor = '#4AAE80';
const profileModifiedTemplateColor = '#4F46E5';
const profileActivatedTemplateColor = '#EF4444';

interface SyspromptManagerProps {
  profile: ProfileSelectionDTO;
}

const defaultTagContents: TagContentReplacer = {
  Tag1: 'Assistant Role',
  Tag2: 'Company Name',
  Tag3: 'Assistant Description',
  Tag4: 'File Names',
  Tag5: 'File Contents',
}

const SyspromptManager: React.FC<SyspromptManagerProps> = ({ profile }) => {
  const [globalTemplates, setGlobalTemplates] = useState<string[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [selectedEditMode, setSelectedEditMode] = useState<EditMode | null>(null);
  const [editContent, setEditContent] = useState<string>('');
  const [newTemplateName, setNewTemplateName] = useState<string>('');
  const [showNewTemplateInput, setShowNewTemplateInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tagReplacer, setTagReplacer] = useState<TagContentReplacer>(defaultTagContents);

  const loadGlobalTemplates = async () => {
    try {
      const response = await getTemplates();
      setGlobalTemplates(response);
    } catch (error) {
      toast.error('Failed to load global templates');
      console.error('Error loading global templates:', error);
    }
  };

  const loadProfileParameters = async () => {
    try {
      const [params, files] = await Promise.all([
        getProfileParameters(profile.id),
        fetchUploadedFiles(profile.id)
      ]);
      setTagReplacer({
        ...tagReplacer,
        Tag1: params.digital_assistant,
        Tag2: params.company_name,
        Tag3: params.assistant_description,
        Tag4: JSON.stringify(files.files),
      });
    } catch (error) {
      toast.error('Failed to load profile parameters');
      console.error('Error loading profile parameters:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await loadGlobalTemplates();
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await loadGlobalTemplates();
      await loadProfileParameters();
    };
    fetchData();
  }, [profile.id]);

  const handleReload = async () => {
    await loadGlobalTemplates();
    if (selectedTemplate && selectedEditMode) {
      switch (selectedEditMode) {
        case 'template':
          handleLoadTemplate(selectedTemplate);
          break;
        case 'profileModified':
          handleLoadProfileModified();
          break;
        case 'profileActivated':
          handleLoadProfileActivated();
          break;
      }
    }
  };

  const handleTemplateDeletion = async (templateName: string) => {
    if (selectedTemplate === templateName) {
      await handleCancel();
    }
    try {
      await deleteGlobalTemplate(templateName);
      await handleReload();
      toast.success('Template deleted successfully');
    } catch (error) {
      toast.error('Failed to delete template');
      console.error('Error deleting template:', error);
    }
  }

  const getDisplayTitle = (templateName: string | null, editMode: EditMode | null) => {
    if (!editMode) return { text: `Select a template`, color: 'inherit' };

    switch (editMode) {
      case 'template':
        return { text: `Template - "${templateName}"`, color: globalTemplateColor };
      case 'profileModified':
        return { text: `Profile - Modified`, color: profileModifiedTemplateColor };
      case 'profileActivated':
        return { text: `Profile - Activated`, color: profileActivatedTemplateColor };
      default:
        return { text: `Selected: ${templateName}`, color: 'inherit' };
    }
  };


  const handleTemplateSelect = async (templateName: string) => {
    handleCancel();
    setSelectedTemplate(templateName);
  };

  const handleCancel = async () => {
    setSelectedTemplate(null);
    setEditContent('');
    setSelectedEditMode(null);
  };

  const handleLoadTemplate = async (templateName: string) => {
    try {
      const template = await getGlobalTemplate(templateName);
      setSelectedEditMode('template');
      setEditContent(template);
    } catch (error) {
      toast.error('Failed to load template');
      console.error('Error loading template:', error);
    }
  };

  const handleLoadProfileModified = async () => {
    try {
      const template = await getProfileModifiedTemplate(profile.id);
      setSelectedEditMode('profileModified');
      setEditContent(template);
    } catch (error) {
      toast.error('Failed to load profile modified template');
      console.error('Error loading profile modified template:', error);
    }
  };

  const handleLoadProfileActivated = async () => {
    try {
      const template = await getProfileActivatedTemplate(profile.id);
      setSelectedEditMode('profileActivated');
      setEditContent(template);
    } catch (error) {
      toast.error('Failed to load profile activated template');
      console.error('Error loading profile activated template:', error);
    }
  };

  const processContentBeforeSaving = (content: string): string => {
    let processedContent = content;
    replaceTagContent(processedContent, defaultTagContents, (newContent) => {
      processedContent = newContent;
    });
    return processedContent;
  };

  const handleSaveTemplate = async () => {
    if (!selectedTemplate || !selectedEditMode) return;
    try {
      const processedContent = processContentBeforeSaving(editContent);
      await saveGlobalTemplate(selectedTemplate, processedContent, false);
      handleCancel();
      toast.success('Template saved successfully');
    } catch (error) {
      toast.error('Failed to save template');
      console.error('Error saving template:', error);
    }
  };

  const handleSaveToProfile = async () => {
    try {
      const processedContent = processContentBeforeSaving(editContent);
      await saveProfileModifiedTemplate(profile.id, processedContent);
      handleCancel();
      toast.success('Changes saved locally');
    } catch (error) {
      toast.error('Failed to save changes locally');
      console.error('Error saving changes locally:', error);
    }
  };

  const handleActivate = async () => {
    if (!selectedEditMode) return;
    try {
      setLoading(true);
      const processedContent = processContentBeforeSaving(editContent);
      await activateProfileTemplate(profile.id, processedContent);
      handleCancel();
      toast.success('Systemprompt activated');
    } catch (error) {
      toast.error('Failed to activate systemprompt');
      console.error('Error activating systemprompt:', error);
    }
    finally {
      setLoading(false);
    }
  };

  const handleSaveNewTemplate = async () => {
    if (!selectedEditMode || !newTemplateName.trim()) {
      toast.error('Please enter a name for the new template');
      return;
    }
    try {
      const processedContent = processContentBeforeSaving(editContent);
      await saveGlobalTemplate(newTemplateName, processedContent, true);
      await loadGlobalTemplates();
      handleCancel();
      setShowNewTemplateInput(false);
      setNewTemplateName('');
      toast.success('New template saved successfully');
    } catch (error) {
      toast.error('Failed to save new template');
      console.error('Error saving new template:', error);
    }
  };

  const FillProfileParams = () => {
    replaceTagContent(editContent, tagReplacer, setEditContent);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="w-full h-full p-6 border-2 border-gray-200 rounded-lg flex flex-col">
      <div className="grid grid-cols-[minmax(150px,200px)_1fr] gap-6 flex-grow overflow-hidden">
        {/* Left Panel */}
        <div className="bg-white rounded-lg shadow-md p-6 border border-teal-500 flex flex-col overflow-auto">
          <h3 className="text-lg font-semibold mb-4">Sysprompt templates</h3>
          <div className="space-y-2 mb-2 overflow-y-auto">
            {globalTemplates.map((template, index) => (
              <div
                key={index}
                className={`flex justify-between items-center p-2 rounded-lg ${selectedTemplate === template ? 'highlighted-div' : 'bg-gray-100'
                  }`}
              >
                <button
                  onClick={async () => {
                    await handleTemplateSelect(template);
                    handleLoadTemplate(template);
                  }}
                  className="text-sm flex-1 text-left truncate-button"
                  title={template}
                >
                  {template}
                </button>
                {!["form-style", "info-style", "job-ad-style"].includes(template) &&
                  <RiDeleteBin6Line
                    onClick={() => handleTemplateDeletion(template)}
                    className="cursor-pointer text-red-500 ml-4"
                  />}
              </div>
            ))}
          </div>
        </div>

        {/* Right Panel */}
        <div className="flex flex-col min-h-0">
          <div className="mb-4 flex justify-between items-center">
            <h3
              className="text-lg font-semibold"
              style={{ color: getDisplayTitle(selectedTemplate, selectedEditMode).color }}
            >
              {getDisplayTitle(selectedTemplate, selectedEditMode).text}
            </h3>
            {/* Load Source Buttons */}
            <div className="flex gap-4 center-items">
              <button
                onClick={handleLoadProfileModified}
                disabled={!!selectedEditMode}
                style={{ backgroundColor: profileModifiedTemplateColor }}
                className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Edit Profile Prompt Modified
              </button>
              <button
                onClick={handleLoadProfileActivated}
                disabled={!!selectedEditMode}
                style={{ backgroundColor: profileActivatedTemplateColor }}
                className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Edit Profile Prompt Activated
              </button>
              {/* Reload Button */}
              <button
                onClick={handleReload}
                className="p-2 text-gray-600 hover:text-gray-900 focus:outline-none active:scale-95 active:bg-gray-200 rounded-full"
                title="Reload"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="flex-grow min-h-0">
            {selectedEditMode ? (
              <div className="h-full">
                <SyspromptTemplateCodeEditor
                  value={editContent}
                  onChange={setEditContent}
                  processText={FillProfileParams}
                />
              </div>
            ) : (
              <div className="flex h-full justify-center items-center w-full text-center text-gray-600 py-12">
                Select a template on the left, or press one of the edit buttons above!
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-between mt-6">
        <div className="flex space-x-4">
          <button
            onClick={(e) => {
              handleCancel();
              (e.target as HTMLElement).blur();  // to make sure the button loses focus
            }}
            className="py-2 px-4 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2"
          >
            Cancel
          </button>
          <button
            onClick={handleSaveTemplate}
            disabled={!selectedEditMode}
            style={{ backgroundColor: globalTemplateColor }}
            className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Overwrite template
          </button>
          <div>
            <button
              onClick={() => setShowNewTemplateInput(true)}
              disabled={!selectedEditMode}
              style={{ backgroundColor: globalTemplateColor }}
              className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Save new template
            </button>
            {showNewTemplateInput && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="bg-white p-6 rounded-lg shadow-lg">
                  <h3 className="text-lg font-semibold mb-4">Save as new template</h3>
                  <input
                    type="text"
                    onChange={(e) => setNewTemplateName(e.target.value)}
                    defaultValue={selectedTemplate ?? ""}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md mb-4"
                    autoFocus
                  />
                  <div className="flex justify-end gap-3">
                    <button
                      onClick={() => {
                        setShowNewTemplateInput(false);
                        setNewTemplateName('');
                      }}
                      className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSaveNewTemplate}
                      disabled={!newTemplateName.trim()}
                      style={{ backgroundColor: globalTemplateColor }}
                      className="px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex space-x-4 md:gap-3">
          <button
            onClick={handleSaveToProfile}
            disabled={!selectedEditMode}
            style={{ backgroundColor: profileModifiedTemplateColor }}
            className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Save to profile
          </button>
          <button
            onClick={handleActivate}
            disabled={!selectedEditMode}
            style={{ backgroundColor: profileActivatedTemplateColor }}
            className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Activate
          </button>
        </div>
      </div>
    </div>
  );
};

export default SyspromptManager;
