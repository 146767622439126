function Loading() {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="absolute inset-0 backdrop-blur-md"></div>
      <div className="z-10 h-32 w-32 animate-spin rounded-full border-t-2 border-b-2 border-blue-500"></div>
    </div>
  );
}
  
  export default Loading;