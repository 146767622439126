import JsonView from '@uiw/react-json-view';
import { useEffect, useState } from 'react';
import { lightTheme } from '@uiw/react-json-view/light';

const JsonViewer = ({ content, isVisible, onClose }: { content: any, isVisible: boolean, onClose: () => void }) => {
  const [modalContent, setModalContent] = useState<JSON>({} as JSON);

    useEffect(() => {
        setModalContent(content);
    }, [content]);

  const handleOutsideClick = (event: any) => {
    if (event.target.id === 'modalBackdrop') {
      onClose();
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isVisible]);

  return (
    isVisible ? (
      <div id="modalBackdrop" onClick={handleOutsideClick} className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
        <div className="bg-white p-6 rounded-lg shadow-xl overflow-auto max-w-3xl max-h-full mx-auto">
            <JsonView value={modalContent} style={lightTheme} displayObjectSize={false} highlightUpdates={false} displayDataTypes={false}/>
            <div className="flex justify-end mt-4">
          <button onClick={onClose} className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
            OK
          </button>
        </div>
        </div>
      </div>
    )
        : null
  );
};

export default JsonViewer;
