import { toast } from "react-toastify";
import { deleteFile } from "../../services/FileService";
import { useEffect, useState } from "react";

const DeleteConfirmation = ({ profileId, file, isVisible, fetchFileLists, onClose }: { profileId: any, file: string, isVisible: boolean, fetchFileLists: any, onClose: () => void }) => {
    const handleOutsideClick = (event: any) => {
        if (event.target.id === 'modalBackdrop') {
            onClose();
        }
    };

    const handleClose = () => {
        onClose();
    };

    const handleFileDeletion = () => {
        try {
            const name = file.split('.').slice(0, -1).join('.');
            const extension = file.split('.').pop();
            if (!name || !extension) throw new Error('Invalid file name');
            deleteFile(profileId, name, extension).then(() => fetchFileLists());
            toast.success(`${file} has been deleted successfully!`);

        } catch (error) {
            toast.error('Failed to delete file.');
            console.error('Error deleting file:', error);
        }
        finally {
            onClose();
        }
    };

    useEffect(() => {
        if (isVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }

        return () => {
            document.body.style.overflow = 'visible';
        };
    }, [isVisible]);

    return (
        isVisible ? (
            <div className="fixed inset-0 z-50 flex items-start justify-center pt-10">
                <div
                    id="modalBackdrop"
                    onClick={handleOutsideClick}
                    className="absolute inset-0"
                >
                    <div className="absolute inset-0 bg-black opacity-30"></div>
                    <div className="absolute inset-0 backdrop-blur-md"></div>
                </div>
                <div className="z-10 bg-white p-6 rounded-lg shadow-xl overflow-auto max-w-h-3xl mx-auto">
                    <h1 className="text-md font-semibold">Are you sure you want to delete {file}?</h1>
                    <div className="flex justify-end mt-4 gap-2">
                        <button onClick={handleFileDeletion} className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                            Delete
                        </button>
                        <button onClick={handleClose} className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        ) : null
    );
};

export default DeleteConfirmation;

