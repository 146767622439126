import { useEffect, useState } from 'react';
import { useProfile } from '../../contexts/ProfileContextType';
import { toast } from 'react-toastify';
import { getProfileParameters, getDefaultParameters, saveProfileParameters } from '../../services/ProfileService';
import Loading from '../utils/Loading';

const ProfileInformation: React.FC = () => {
    const [companyInfo, setCompanyInfo] = useState('');
    const [assistantRole, setAssistantRole] = useState('');
    const [assistantDescription, setAssistantDescription] = useState('');
    const [additionalData, setAdditionalData] = useState('');
    const { selectedProfileId, pdfFormat } = useProfile();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (selectedProfileId === 'select-profile') return;
        getActualProfileParamteres();
    }, [selectedProfileId]);

    const saveActualProfileParameters = async () => {
        if(pdfFormat === 'select-style') {
            toast.info('Please select a profile style first and save it!');
            const element = document.getElementById('pdf-format-selection');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            return;
        }
        setLoading(true);
        await saveProfileParameters(selectedProfileId, companyInfo, assistantRole, assistantDescription, additionalData).then(() => {
            toast.success('Profile parameters saved successfully!');
            const element = document.getElementById('document-management');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }).catch((error) => {
            toast.error('Failed to save profile parameters');
            console.error('Error saving profile parameters:', error);
        }).finally(() => {
            setLoading(false);
        });
    };

    const getDefaultParametersForProfile = async () => {
        if (pdfFormat === 'select-style') {
            toast.info('Please select a profile style first and save it!');
            const element = document.getElementById('pdf-format-selection');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            return;
        }
        setLoading(true);
        getDefaultParameters(pdfFormat)
            .then((data) => {
                setCompanyInfo(data.company_name);
                setAssistantRole(data.digital_assistant);
                setAssistantDescription(data.assistant_description);
                setAdditionalData(data.information);
                toast.success('Default parameters fetched successfully!');
            })
            .catch((error) => {
                toast.error('Failed to fetch default parameters');
                console.error('Error fetching default parameters:', error);
            }).finally(() => {
                setLoading(false);
            });
    };

    const getActualProfileParamteres = () => {
        getProfileParameters(selectedProfileId)
            .then((data) => {
                setCompanyInfo(data.company_name);
                setAssistantRole(data.digital_assistant);
                setAssistantDescription(data.assistant_description);
                setAdditionalData(data.information);
            })
            .catch((error) => {
                toast.error('Failed to fetch profile parameters');
                console.error('Error fetching profile parameters:', error);
            });
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="max-w-4xl mx-auto my-10 bg-white rounded-lg shadow-md p-6 border border-teal-500">
            <h2 className="text-xl md:text-2xl font-semibold mb-4 text-gray-800">Step 2: Provide Profile Information</h2>
            <div className="mb-4">
                <label htmlFor="company-name-input" className="block text-gray-700 font-medium">
                    Company name, short description, and location:
                </label>
                <input
                    type="text"
                    id="company-name-input"
                    value={companyInfo}
                    onChange={(e) => setCompanyInfo(e.target.value)}
                    placeholder="Enter your company info"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="digital-assistant-role-input" className="block text-gray-700 font-medium">
                    Your assistant's position:
                </label>
                <input
                    type="text"
                    id="digital-assistant-role-input"
                    value={assistantRole}
                    onChange={(e) => setAssistantRole(e.target.value)}
                    placeholder="e.g., Digital Quality Manager"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>
            <div className="mb-4">
                <label htmlFor="assistant-description-input" className="block text-gray-700 font-medium">
                    Assistant description:
                </label>
                <textarea
                    id="assistant-description-input"
                    value={assistantDescription}
                    onChange={(e) => setAssistantDescription(e.target.value)}
                    placeholder="Enter your assistant's description"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    rows={3}
                />
            </div>
            <div className="mb-4">
                <label htmlFor="additional-data-input" className="block text-gray-700 font-medium">
                    Additional data list in JSON format (optional):
                </label>
                <textarea
                    id="additional-data-input"
                    value={additionalData}
                    onChange={(e) => setAdditionalData(e.target.value)}
                    placeholder="Enter additional data"
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    rows={4}
                />
            </div>
            <div className="flex justify-end gap-4">
                <button
                    onClick={getDefaultParametersForProfile}
                    className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                >
                    Default Parameters
                </button>
                <button
                    onClick={saveActualProfileParameters}
                    className="py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                    id="profile-informations"
                >
                    Save Parameters
                </button>
            </div>
        </div>
    );
};

export default ProfileInformation;
