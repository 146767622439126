import { useProfile } from '../../contexts/ProfileContextType';
import React, { useEffect, useRef, useState } from 'react';

// MyChatbotWidget function. Should be defined in accordance with chatbot-widget.js
declare global {
    function MyChatbotWidget(
        chatbotDiv: string,
        profileId: string,
        welcomeMessage?: string,
        width?: string,
        height?: string
    ): void;
}

const Chatbot: React.FC = () => {
    const { selectedProfileId } = useProfile();
    const chatbotRef = useRef<HTMLDivElement>(null);
    const [scriptLoaded, setScriptLoaded] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://webchat.bf-ai.com/chatbot-widget.js';
        script.async = true;

        const handleLoad = () => {
            console.log('Chatbot script loaded successfully');
            setScriptLoaded(true);
        };

        const handleError = () => {
            console.error('Failed to load the chatbot script');
        };

        script.addEventListener('load', handleLoad);
        script.addEventListener('error', handleError);

        document.head.appendChild(script);

        return () => {
            script.removeEventListener('load', handleLoad);
            script.removeEventListener('error', handleError);
            if (script.parentNode) {
                script.parentNode.removeChild(script);
            }
        };
    }, []);

    useEffect(() => {
        if (scriptLoaded && chatbotRef.current && typeof MyChatbotWidget === 'function') {
            console.log('Initializing chatbot with profile ID:', selectedProfileId);
            MyChatbotWidget(
                '#my-chatbot-container',
                selectedProfileId
            );
        }
    }, [scriptLoaded, selectedProfileId]);

    return <div id="my-chatbot-container" ref={chatbotRef}></div>;
};

export default Chatbot;
