import { ProfileSelectionDTO } from "../../types/ProfileSelectionDTO";
import SyspromptManager from "../admin/SyspromptManager";

interface EditSyspromptTemplateProps {
  profile: ProfileSelectionDTO;
  isVisible: boolean;
  onClose: () => void;
}

const SyspromptManagementModal = ({ profile, isVisible, onClose }: EditSyspromptTemplateProps) => {
  return isVisible ? (
    <div id="modalBackdrop" className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-start pt-10 z-50">
      <div className="relative top-20 mx-auto p-5 border w-[90%] h-[80%] shadow-lg rounded-md bg-white">
        <button
          className="absolute top-1 right-1 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <div className="m-4 h-[95%]">
          <SyspromptManager profile={profile} />
        </div>
      </div>
    </div>
  ) : null;
};

export default SyspromptManagementModal;
