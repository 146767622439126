import React, { useState, useEffect } from 'react';
import { getUsers, resetPassword } from '../../services/UserService';
import ChangePassword from '../modals/ChangePassword';
import SyspromptManagementModal from '../modals/SyspromptManagementModal';
import { toast } from 'react-toastify';
import { fetchAllProfiles } from '../../services/ProfileService';
import { ProfileSelectionDTO } from '../../types/ProfileSelectionDTO';

const AdminPanel: React.FC = () => {
  const [users, setUsers] = useState<string[]>([]);
  const [showChangePasswordModal, setShowModal] = useState<boolean>(false);
  const [showSyspromptModal, setShowSyspromptModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [selectedProfile, setSelectedProfile] = useState<ProfileSelectionDTO | null>(null);
  const [message, setMessage] = useState<string>('');
  const [profiles, setProfiles] = useState<ProfileSelectionDTO[]>();


  useEffect(() => {
    getUsers().then((data) => {
      setUsers(data);
    }).catch((error) => {
      console.error('Failed to fetch users:', error);
    });
    fetchAllProfiles().then((data) => {
      setProfiles(data);
    }).catch((error) => {
      console.error('Failed to fetch profiles:', error);
    });
  }, []);

  const handleOpenModal = (username: string): void => {
    setSelectedUser(username);
    setShowModal(true);
  };

  const handleCloseChangePasswordModal = (): void => {
    setShowModal(false);
    setMessage('');
  };

  const handleResetPassword = (username: string): void => {
    resetPassword(username).then(() => {
      toast.success(`Password reset for user: ${username}`);
    }).catch((error) => {
      toast.error('Failed to reset password');
      console.error('Failed to reset password:', error);
    });
  }

  const handleCopyLink = (username: string) => {
    const loginUrl = `${window.location.origin}/login?username=${username}`;
    navigator.clipboard.writeText(loginUrl)
      .then(() => {
        toast.success("Link copied to clipboard!");
      })
      .catch(err => {
        toast.error("Failed to copy link.");
        console.error('Failed to copy text: ', err);
      });
  };

  const handleOpenSyspromptModal = (profile: ProfileSelectionDTO): void => {
    setSelectedProfile(profile);
    setShowSyspromptModal(true);
  };

  const handleCloseSyspromptModal = (): void => {
    setShowSyspromptModal(false);
    setSelectedProfile(null);
  };

  return (
    <div className="max-w-4xl mx-auto my-10 bg-white rounded-lg shadow-lg overflow-hidden border border-teal-500">
      <div className="p-6">
        <h1 className="text-2xl font-bold text-gray-900">Users</h1>
        <ul className="space-y-1 mt-4">
          {users.map((user: string, index: number) => {
            const userProfile = profiles?.find(
              (profile) => profile.name.toLowerCase() === user[0]
            );
            return (
              <li
                key={index}
                className="flex font-bold justify-between items-center bg-white p-4 shadow-sm rounded-md border border-gray-300"
              >
                <span className="font-bold text-gray-700">
                  {user} {userProfile?.saved_name && `- ${userProfile.saved_name}`}
                </span>

                <div className="flex flex-wrap gap-2">
                  <button
                    onClick={() => handleCopyLink(user)}
                    className="inline-flex items-center px-3 py-2 text-sm bg-teal-500 hover:bg-teal-700 text-white rounded-md transition-colors"
                  >
                    Copy Login Link
                  </button>

                  <button
                    onClick={() => handleOpenModal(user[0])}
                    className="inline-flex items-center px-3 py-2 text-sm bg-teal-500 hover:bg-teal-700 text-white rounded-md transition-colors"
                  >
                    Change Password
                  </button>

                  <button
                    onClick={() => handleResetPassword(user[0])}
                    className="inline-flex items-center px-3 py-2 text-sm bg-teal-500 hover:bg-teal-700 text-white rounded-md transition-colors"
                  >
                    Reset Password
                  </button>

                  {userProfile && (
                    <button
                      onClick={() => handleOpenSyspromptModal(userProfile)}
                      className="inline-flex items-center px-3 py-2 text-sm bg-teal-500 hover:bg-teal-700 text-white rounded-md transition-colors"
                    >
                      Sysprompt Management
                    </button>
                  )}
                </div>
              </li>
            )
          })}
        </ul>
      </div>
      {showChangePasswordModal && (
        <ChangePassword
          selectedUser={selectedUser}
          isVisible={showChangePasswordModal}
          onClose={handleCloseChangePasswordModal}
        />
      )}
      {showSyspromptModal && selectedProfile && (
        <SyspromptManagementModal
          profile={selectedProfile}
          isVisible={showSyspromptModal}
          onClose={handleCloseSyspromptModal}
        />
      )}
    </div>
  );
};


export default AdminPanel;
