import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthState';

const ProtectedRoute = ({ allowedRoles }: { allowedRoles: string[] }) => {
    const { isAuthenticated, accessToken } = useAuth();
    const userRole = accessToken?.role ?? '';
    const isAuthorized = isAuthenticated && allowedRoles.includes(userRole);

    return isAuthorized ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;