import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { changePassword } from "../../services/UserService";

const ChangePassword = ({ selectedUser, isVisible, onClose }: { selectedUser: string, isVisible: boolean, onClose: () => void }) => {
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const handleOutsideClick = (event: any) => {
        if (event.target.id === 'modalBackdrop') {
            onClose();
        }
    };

    const handleClose = () => {
        onClose();
    };

    const handleChangePassword = async () => {
        if (newPassword !== confirmPassword) {
            toast.info('Passwords do not match!')
            return;
        }
        try {
            await changePassword(selectedUser, newPassword);
            toast.success('Password changed successfully!');
        } catch (error) {
            console.error('Failed to change password:', error);
            toast.error('Failed to change password!');
        }
        finally {
            onClose();
        }
    }

    useEffect(() => {
        if (isVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }

        return () => {
            document.body.style.overflow = 'visible';
        };
    }, [isVisible]);

    return (
        isVisible ? (
            <div id="modalBackdrop" onClick={handleOutsideClick} className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-start pt-10"
            >
                <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
                    <div className="mt-3 text-center">
                        <h3 className="text-lg leading-6 font-medium text-gray-900 font-semibold">Change Password for <span className="font-bold">{selectedUser}</span></h3>
                        <div className="mt-2 px-7 py-3">
                            <input type="password" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} placeholder="New Password" />
                            <input type="password" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline my-4" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm Password" />
                        </div>
                        <div className="items-center px-4 py-3">
                            <button className="px-4 py-2 bg-teal-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-teal-300" onClick={handleChangePassword}>
                                Save Changes
                            </button>
                            <button className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300 mt-3" onClick={handleClose}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
            : null
    );
};

export default ChangePassword;

