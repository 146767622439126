import axios from 'axios';
import { API_BASE_URL } from '../Config';

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token && config.url != `${API_BASE_URL}/login`) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        console.error('Failed to set token:', error);
        return Promise.reject(error);
    }
  );

axios.interceptors.response.use(response => response, error => {
    if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        if(window.location.pathname !== '/login') window.location.reload();
    }
    return Promise.reject(error);
});
  
// TODO: Check if this function is actually needed at all
const getJSONFormat = async (profileIndex: string, file: File,smartProcessing:boolean) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('smart_processing', String(smartProcessing));
    try {
        const response = await axios.post(`${API_BASE_URL}/getJSONFormat?profile_index=${profileIndex}`, formData, {
            /*headers: {
                'Content-Type': 'multipart/form-data',
            },*/
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch JSON format:', error);
        throw error;
    }
};

const getReprocessedJSON = async (profileIndex: string, filename: string, smartProcessing:boolean) => {
    const formData = new FormData();
    formData.append('filename', filename);
    formData.append('smart_processing', String(smartProcessing));
    try {
        const response = await axios.post(`${API_BASE_URL}/getReprocessedJSON?profile_index=${profileIndex}`, formData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch Reprocessed JSON:', error);
        throw error;
    }
}

const fetchUploadedFiles = async (id: string): Promise<{files: string[]}> => {
    try {
        const response = await axios.get(`${API_BASE_URL}/get-uploaded-files?profile_index=${id}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch uploaded files:', error);
        throw error;
    }
}


const fetchGeneratedFiles = async (id: string) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/get-generated-files?profile_index=${id}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch generated files:', error);
        throw error;
    }
}

const getCompleteJson = async (id: string, filename: string) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/get-complete-json?filename=${filename}&profile_index=${id}`);
        return response.data[0];
    } catch (error) {
        console.error('Failed to fetch complete JSON:', error);
        throw error;
    }
}

const deleteFile = async (id: string, filename: string, extension: string) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/delete_document?profile_index=${id}`, {
            file_name: filename,
            extension: extension
        });
        return response.data;
    } catch (error) {
        console.error('Failed to delete file:', error);
        throw error;
    }
}

const saveJson = async (id: string, filename: string, json: any) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/save-json?profile_index=${id}&fileName=${filename}`, json);
        return response.data;
    } catch (error) {
        console.error('Failed to save JSON:', error);
        throw error;
    }
}

const overwriteJson = async (id: string, filename: string, json: any) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/overwrite-json?profile_index=${id}&fileName=${filename}`, json);
        return response.data;
    } catch (error) {
        console.error('Failed to overwrite JSON:', error);
        throw error;
    }
}

const activateDocuments = async (id: string, selectedFileIds: string[], baseline: string) => {
    try {
        const selectedFileIdsWithoutExtension = selectedFileIds.map((fileId) => fileId.split('.')[0]);
        const response = await axios.post(`${API_BASE_URL}/send_files?profile_index=${id}&baseline=${baseline}`, {
            file_ids: selectedFileIdsWithoutExtension
        });
        return response.data;
    } catch (error) {
        console.error('Failed to activate documents:', error);
        throw error;
    }
}

const getGeneratedJson = async (profileIndex: string, fileName: string, fileType: string) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/get_edited_jsons?profile_index=${profileIndex}`, {
            file_name: fileName,
            file_type: fileType
        });
        // const json = JSON.parse(response.data.file_content);
        // return JSON.stringify(json, null, 2);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch file content:', error);
        throw error;
    }
};


export { 
    getJSONFormat,
    getReprocessedJSON,
    fetchUploadedFiles,
    fetchGeneratedFiles,
    getCompleteJson,
    deleteFile,
    saveJson,
    overwriteJson,
    activateDocuments,
    getGeneratedJson 
};