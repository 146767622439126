import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/AuthState';

const Header: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {accessToken, logoutCall} = useAuth();
    const isVisible = location.pathname !== '/login';

    const handleButtonClick = () => {
        const { pathname } = location;
        if(pathname === '/admin' || pathname === '/') {
            logoutCall();
            navigate('/login');
        }
        else{
            navigate('/login');
        }
    };


    
    return (
        <div className="bg-white shadow-md rounded-lg px-8 py-6 mb-6 text-center border border-teal-500">
            <h1 className="text-xl md:text-2xl font-bold text-gray-800 mb-4">
                Unlock Efficiency with Miss QuA – Your AI-Powered Assistant available 24/7!
            </h1>
            <h2 className="text-lg font-semibold text-gray-700 mb-2">
                Dive into seamless conversation to collect the details for your forms: Just let your users chat,
                and let us handle the details!
            </h2>
            <p className="text-gray-600">
                On this page you can go through each step to define your industry and the
                digital assistant you need in your business process automation.
            </p>
            {isVisible && ( <div className="bg-white text-end">
                <button
                    onClick={handleButtonClick}
                    className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                    aria-label="Sign out">
                    {location.pathname === '/' || location.pathname === '/admin' ? 'Sign out' : 'Back to home'}
                </button>
            </div>)}
        </div>
    );
};

export default Header;
