import { useState, useEffect, useCallback } from 'react';
import { login } from '../services/UserService';
import { Token } from '../types/Token';
import { jwtDecode } from 'jwt-decode';

interface AuthState {
    isAuthenticated: boolean;
    loginCall: (username: string, password: string) => Promise<void>;
    logoutCall: () => void;
    accessToken: Token | undefined;
}

export const useAuth = (): AuthState => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!localStorage.getItem('token'));
    const [accessToken, setAccessToken] = useState<Token | undefined>(localStorage.getItem('token') ? jwtDecode(localStorage.getItem('token') as string) as Token : undefined);

    useEffect(() => {
        const syncLogout = (event: StorageEvent) => {
            if (event.key === 'logout') {
                setIsAuthenticated(false);
                localStorage.removeItem('token');
            }
        };

        window.addEventListener('storage', syncLogout);
        return () => window.removeEventListener('storage', syncLogout);
    }, []);

    const loginCall = useCallback(async (username: string, password: string): Promise<void> => {
        const response = await login(username, password);
        const decodedToken = jwtDecode(response.access_token);
        setAccessToken(decodedToken as Token);
        setIsAuthenticated(true);
    }, []);

    const logoutCall = useCallback((): void => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
    }, []);

    return {
        isAuthenticated,
        loginCall,
        logoutCall,
        accessToken,
    };
};
