import axios from 'axios';
import { API_BASE_URL } from '../Config';

axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token && config.url != `${API_BASE_URL}/login`) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        console.error('Failed to set token:', error);
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(response => response, error => {
    if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        if (window.location.pathname !== '/login') window.location.reload();
    }
    return Promise.reject(error);
});

const getTemplates = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/baselines`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch baselines:', error);
        throw error;
    }
};

const getGlobalTemplate = async (templateName: string) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/baseline-template`, {
            params: { baseline: templateName }
        });
        return response.data.template;
    } catch (error) {
        console.error('Failed to fetch baseline template:', error);
        throw error;
    }
};

const getProfileModifiedTemplate = async (profileId: string) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/profile-modified-template`, {
            params: { profile_id: profileId }
        });
        return response.data.template;
    } catch (error) {
        console.error('Failed to fetch profile modified template:', error);
        throw error;
    }
};

const getProfileActivatedTemplate = async (profileId: string) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/profile-activated-template`, {
            params: { profile_id: profileId }
        });
        return response.data.template;
    } catch (error) {
        console.error('Failed to fetch profile activated template:', error);
        throw error;
    }
};

const saveGlobalTemplate = async (templateName: string, templateText: string, isNew: boolean = false) => {
    try {
        const method = isNew ? 'put' : 'patch';
        const response = await axios[method](`${API_BASE_URL}/baseline-template`,
            { template: templateText },
            { params: { baseline: templateName } }
        );
        return response.data;
    } catch (error) {
        console.error(`Failed to ${isNew ? 'create' : 'update'} baseline template:`, error);
        throw error;
    }
};

const saveProfileModifiedTemplate = async (profileId: string, template: string) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/profile-modified-template`,
            { template },
            { params: { profile_id: profileId } }
        );
        return response.data;
    } catch (error) {
        console.error('Failed to save profile modified template:', error);
        throw error;
    }
};

const activateProfileTemplate = async (profileId: string, template: string) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/profile-template/activate`,
            { template },
            { params: { profile_id: profileId } }
        );
        return response.data;
    } catch (error) {
        console.error('Failed to activate profile template:', error);
        throw error;
    }
};

const deleteGlobalTemplate = async (templateName: string) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/baseline-template`, {
            params: { baseline: templateName }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to delete baseline template:', error);
        throw error;
    }
};

export {
    getTemplates,
    getGlobalTemplate,
    getProfileModifiedTemplate,
    getProfileActivatedTemplate,
    saveGlobalTemplate,
    saveProfileModifiedTemplate,
    activateProfileTemplate,
    deleteGlobalTemplate
};
