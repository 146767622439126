import React from 'react';
import Footer from '../footer/Footer';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="container mx-auto mt-10 mb-10 p-5 bg-white rounded-lg shadow">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy for Ask-QuA</h1>
        <p className="mb-4"><strong>Effective Date:</strong> January 26, 2024</p>
        <p className="mb-4">Bright Future AI respects the privacy of the users of our application Ask-QuA. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our App. Please read this privacy policy carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE APPLICATION.</p>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Collection of Your Information</h2>
          <p className="mb-4">We may collect information about you in a variety of ways. The information we may collect via the App includes:</p>
          <ul className="list-disc pl-8 mb-4">
            <li><strong>Geolocation Information:</strong> We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our App, to provide location-based services. If you wish to change our access or permissions, you may do so in your device's settings.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Use of Your Information</h2>
          <ul className="list-disc pl-8 mb-4">
            <li>To provide, operate, and maintain our App;</li>
            <li>To improve the functionality and user experience of our App;</li>
            <li>To understand and analyze how you use our App;</li>
            <li>To develop new products, services, features, and functionality.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Disclosure of Your Information</h2>
          <ul className="list-disc pl-8 mb-4">
            <li><strong>By Law or to Protect Rights:</strong> If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Security of Your Information</h2>
          <p className="mb-4">We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Options Regarding Your Information</h2>
          <p className="mb-4">You may at any time review or change the information in your account or terminate your account by contacting us using the contact information provided.</p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold mb-4">Policy for Children</h2>
          <p className="mb-4">We do not knowingly solicit information from or market to children under the age of 13. If you become aware of any data we have collected from children under age 13, please contact us using the contact information provided.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
          <p>If you have questions or comments about this Privacy Policy, please contact us at: info@bf-ai.com.</p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
