import { useCallback, useEffect, useRef } from 'react';
import CodeMirror, { ReactCodeMirrorRef } from '@uiw/react-codemirror';
import { syspromptEditorConfig } from '../utils/syspromptEditorUtils';

interface SyspromptTemplateCodeEditorProps {
  value: string;
  onChange: (value: string) => void;
  processText?: () => void;
}

export const SyspromptTemplateCodeEditor = ({ value, onChange, processText }: SyspromptTemplateCodeEditorProps) => {
  const editorRef = useRef<ReactCodeMirrorRef>(null);

  useEffect(() => {
    if (editorRef.current?.view?.dom) {
      const editorElement = editorRef.current.view.dom;
      editorElement.style.height = '100%';
      editorElement.style.width = '100%';
    }
  }, [value, editorRef.current]);

  useEffect(() => {
    if (!processText) {
      return;
    }

    // Initial run
    processText();
    // Set interval to run every 5 seconds
    const intervalId = setInterval(processText, 5000);

    return () => clearInterval(intervalId);
  }, [processText]);

  return (
    <div className="flex flex-col h-full">
      <label className="block text-sm font-medium text-gray-700 mb-2">
        Set Systemprompt Template
      </label>
      <div className="flex-grow h-full w-full p-1 border rounded-md shadow-sm">
        <CodeMirror
          ref={editorRef}
          value={value}
          onChange={onChange}
          height="100%"
          width="100%"
          style={{ height: '100%' }}
          theme={syspromptEditorConfig.theme}
          extensions={syspromptEditorConfig.extensions}
        />
      </div>
    </div>
  );
};
